@font-face {
  font-family: "Hanford";
  src:
    local("./assets/fonts/HANFORD.TTF"),
    url(./assets/fonts/HANFORD.TTF) format("truetype");
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

body {
  background-color: black;
  color: white;
}

h1 {
  text-align: center;
  font-family: "Hanford", "Georgia", serif;
  font-size: 4rem;
  letter-spacing: 0.25rem;
  margin-bottom: 0;
}

h3 {
  text-align: center;
  font-size: 2rem;
  margin-top: 0;
  margin-bottom: 4rem;
  font-weight: lighter;
}

.title-image-container {
  padding-top: 2rem;
  display: flex;
  justify-content: center;
  background-color: black;
  padding: 2rem;
  img {
    width: 100%;
    max-width: 920px;
  }
}

.intro-text {
  margin-bottom: 4rem;
  text-align: center;
}

.startpage {
  padding: 1rem 1rem 4rem 1rem;
  background-color: rgb(60, 60, 60);

  .masonry {
    img {
      margin: 4px;
      // border: 12px solid white;
      border-radius: 0.125rem;
      cursor: pointer;
    }
  }
  .year-headline {
    margin-top: 2rem;
    margin-bottom: 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
    font-weight: 300;
  }
}
footer {
  height: 64px;
  background-color: rgb(24, 24, 24);
  display: flex;
  justify-content: space-between;
  align-items: center;
  
  .emoji {
    margin-right: 1rem;
    cursor: pointer;
  }
  .heart {
    margin: 0 6px;
    cursor: pointer;
  }

  div {
    margin-left: 16px;
    margin-right: 16px;
  }
}
